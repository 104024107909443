// Promise polyfill
import 'core-js/features/promise';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import '@amzn/awsui-global-styles/polaris.css';
import { Provider } from 'react-redux';
import { store } from './state/store';
import axios from 'axios';
import sigv4Interceptor from './auth/AxiosSigv4Interceptor';
import { setupAuth, signIn } from './auth/AmplifyAuthToken';
import ApiFactory from './open-api/ApiFactory';
import { DefaultApi } from './open-api/generated-src/api';
import { initializeAppSettings } from './config/AppSettings';

async function initializeApp() {
    await initializeAppSettings();
    setupAuth().then((authenticated) => {
        if (authenticated) {
            renderApp();
        } else {
            signIn();
        }
    });
    axios.interceptors.request.use(sigv4Interceptor);
    apiInstance.setApi(ApiFactory());
}

function renderApp() {
    const root = ReactDOM.createRoot(document.getElementById('app') as HTMLElement);

    root.render(
        <StrictMode>
            <Provider store={store}>
                <App />
            </Provider>
        </StrictMode>
    );
}

function createApiInstance() {
    let api: DefaultApi | null = null;

    const getApi = () => api;

    const setApi = (newApi: DefaultApi | null) => {
        api = newApi;
    };

    return {
        getApi,
        setApi
    };
}

export const apiInstance = createApiInstance();
initializeApp();
