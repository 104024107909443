import { useCallback } from 'react';
import { FlashbarProps } from '@amzn/awsui-components-react';
import { nanoid } from '@reduxjs/toolkit';

export type AddFlashMessageType = ({
    type,
    id,
    content,
    dismissible
}: {
    type: FlashbarProps.Type;
    id?: string;
    content: string;
    dismissible?: boolean;
}) => void;

export default function useFlashMessage({ setFlashMessages }) {
    const addFlashMessage: AddFlashMessageType = useCallback(
        ({ type, id, content, dismissible }) => {
            const newId = id || nanoid();
            setFlashMessages((prev) => [
                {
                    type,
                    dismissible: dismissible === undefined ? true : dismissible,
                    dismissLabel: 'Dismiss message',
                    onDismiss: () =>
                        setFlashMessages((items) => items.filter((item) => item.id !== newId)),
                    id: newId,
                    content
                },
                ...prev
            ]);
        },
        [setFlashMessages]
    );
    return { addFlashMessage };
}
