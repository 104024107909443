import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Header,
    SpaceBetween,
    Box,
    Button,
    TableProps,
    FormField,
    Modal,
    Input,
    ButtonDropdown
} from '@amzn/awsui-components-react';
import { useLocation } from 'react-router-dom';
import { exportDataToCSV, exportDataToJson, exportDataToXLSX } from '../../utils/commons';
import {
    operationConfigs,
    OperationType,
    DynamicPartInput,
    subscribePart,
    subscribePartsList,
    unsubscribePart,
    unsubscribePartsList
} from './partSubscriptionUtils';
import { RetrievedPartData } from '../../open-api/generated-src/api';
import { AddFlashMessageType } from '../hooks/useFlashMessage';

// Table header content, shows how many parts are selected and contains the action stripe
interface PartsTableHeaderProps {
    parts: RetrievedPartData[];
    selectedParts: RetrievedPartData[];
    counter: string;
    columnDefinitions: TableProps.ColumnDefinition<RetrievedPartData>[];
    addFlashMessage: AddFlashMessageType;
}

export default function PartsTableHeader({
    parts,
    selectedParts,
    counter,
    columnDefinitions,
    addFlashMessage
}: PartsTableHeaderProps) {
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [isSubscribeButtonEnabled, setIsSubscribeButtonEnabled] = useState<boolean>(false);
    const [isUnsubscribeButtonEnabled, setIsUnsubscribeButtonEnabled] = useState<boolean>(false);
    const isOnlyOneSelected = selectedParts.length === 1;
    const location = useLocation();
    const navigate = useNavigate();
    const onClickHandler = () => {
        const id = selectedParts[0].id;
        navigate(`${location.pathname}/${id}`);
    };

    const handleZ2DataOperation = async <T extends OperationType>(
        operation: T,
        setButtonEnabled: (enabled: boolean) => void,
        operationSingePartFunction: (part: DynamicPartInput<T>) => Promise<void>,
        operationListPartsFunction: (parts: DynamicPartInput<T>[]) => Promise<void>
    ) => {
        setButtonEnabled(false);

        const selectedPartsInput = selectedParts
            .filter(
                (
                    part
                ): part is Required<
                    Pick<RetrievedPartData, 'manufacturer' | 'manufacturerPartNumber'>
                > &
                    RetrievedPartData => !!part.manufacturer && !!part.manufacturerPartNumber
            )
            .map((part) => ({
                manufacturer: part.manufacturer,
                manufacturerPartNumber: part.manufacturerPartNumber,
                internalPartNumber: part.internalPartNumber
            }));

        const isSinglePart = selectedPartsInput.length === 1;

        try {
            if (selectedPartsInput.length === 0) {
                throw new Error('No parts selected');
            }

            const func = isSinglePart
                ? () => operationSingePartFunction(selectedPartsInput[0] as DynamicPartInput<T>)
                : () => operationListPartsFunction(selectedPartsInput as DynamicPartInput<T>[]);

            await func();
            addFlashMessage({
                type: 'success',
                dismissible: true,
                content: isSinglePart
                    ? operationConfigs[operation].successMessageSingle
                    : operationConfigs[operation].successMessageMultiple
            });
        } catch (error) {
            addFlashMessage({
                type: 'error',
                dismissible: true,
                content: isSinglePart
                    ? operationConfigs[operation].errorMessageSingle(error)
                    : operationConfigs[operation].errorMessageMultiple(error)
            });
        } finally {
            setButtonEnabled(true);
        }
    };

    const subscribeToZ2DataHandler = () =>
        handleZ2DataOperation<OperationType.Subscribe>(
            OperationType.Subscribe,
            setIsSubscribeButtonEnabled,
            subscribePart,
            subscribePartsList
        );

    const unsubscribeToZ2DataHandler = () =>
        handleZ2DataOperation<OperationType.Unsubscribe>(
            OperationType.Unsubscribe,
            setIsUnsubscribeButtonEnabled,
            unsubscribePart,
            unsubscribePartsList
        );

    useEffect(() => {
        if (selectedParts.length > 0) {
            setIsSubscribeButtonEnabled(true);
            setIsUnsubscribeButtonEnabled(true);
        } else {
            setIsSubscribeButtonEnabled(false);
            setIsUnsubscribeButtonEnabled(false);
        }
    }, [selectedParts]);

    return (
        <Header
            variant='h1'
            counter={counter}
            actions={
                <SpaceBetween direction='horizontal' size='s'>
                    <Button disabled={!isOnlyOneSelected} onClick={onClickHandler}>
                        {' '}
                        View details{' '}
                    </Button>
                    <Button disabled={!isSubscribeButtonEnabled} onClick={subscribeToZ2DataHandler}>
                        {' '}
                        Subscribe to Z2Data{' '}
                    </Button>
                    <Button
                        disabled={!isUnsubscribeButtonEnabled}
                        onClick={unsubscribeToZ2DataHandler}
                    >
                        {' '}
                        Unsubscribe from Z2Data{' '}
                    </Button>

                    <Button disabled={selectedParts.length < 1} onClick={() => {}}>
                        {' '}
                        Update to Agile{' '}
                    </Button>
                    <ButtonDropdown
                        onItemClick={(e) => {
                            const id = e.detail.id;
                            switch (id) {
                                case 'all_excel':
                                    exportDataToXLSX(parts, 'all_parts.xlsx', columnDefinitions);
                                    break;
                                case 'all_csv':
                                    exportDataToCSV(
                                        'text/csv',
                                        parts,
                                        'all_parts.csv',
                                        columnDefinitions
                                    );
                                    break;
                                case 'all_json':
                                    exportDataToJson(
                                        'application/json',
                                        parts,
                                        'all_parts.json',
                                        columnDefinitions
                                    );
                                    break;
                                case 'selected_excel':
                                    exportDataToXLSX(
                                        selectedParts,
                                        'selected_parts.xlsx',
                                        columnDefinitions
                                    );
                                    break;
                                case 'selected_csv':
                                    exportDataToCSV(
                                        'text/csv',
                                        selectedParts,
                                        'selected_parts.csv',
                                        columnDefinitions
                                    );
                                    break;
                                case 'selected_json':
                                    exportDataToJson(
                                        'application/json',
                                        parts,
                                        'selected_parts.json',
                                        columnDefinitions
                                    );
                                    break;
                                default:
                                    break;
                            }
                        }}
                        items={[
                            {
                                text: 'Download All Parts(CSV)',
                                id: 'all_csv'
                            },
                            {
                                text: 'Download All Parts(EXCEL)',
                                id: 'all_excel'
                            },
                            {
                                text: 'Download All Parts(JSON)',
                                id: 'all_json'
                            },
                            {
                                text: 'Download Selected Parts(CSV)',
                                id: 'selected_csv'
                            },
                            {
                                text: 'Download Selected Parts(EXCEL)',
                                id: 'selected_excel'
                            },
                            {
                                text: 'Download Selected Parts(JSON)',
                                id: 'selected_json'
                            }
                        ]}
                    >
                        {' '}
                        Export Parts{' '}
                    </ButtonDropdown>
                    <ButtonDropdown
                        onItemClick={(e) => {
                            if (e.detail.id === 'add-a-part') {
                                navigate(`${location.pathname}/add-a-part`);
                            } else {
                                navigate(`${location.pathname}/import-multiple-parts-from-CSV`);
                            }
                        }}
                        items={[
                            {
                                text: 'Add a Part',
                                id: 'add-a-part'
                            },
                            {
                                text: 'Import Multiple Parts from CSV',
                                id: 'import-multiple-parts-from-CSV'
                            }
                        ]}
                        variant='primary'
                    >
                        Add Part(s)
                    </ButtonDropdown>
                    <Modal
                        onDismiss={() => setModalVisible(false)}
                        visible={modalVisible}
                        footer={
                            <Box float='right'>
                                <SpaceBetween direction='horizontal' size='xs'>
                                    <Button variant='link' onClick={() => setModalVisible(false)}>
                                        Cancel
                                    </Button>
                                    <Button
                                        variant='primary'
                                        onClick={() => setModalVisible(false)}
                                    >
                                        Ok
                                    </Button>
                                </SpaceBetween>
                            </Box>
                        }
                        header='Add Compliance Retriever'
                    >
                        <SpaceBetween direction='vertical' size='l'>
                            <FormField label='Part Id'>
                                <Input value='' onChange={() => {}} />
                            </FormField>
                            <FormField label='Retrieval Source'>
                                <Input value='' onChange={() => {}} />
                            </FormField>
                        </SpaceBetween>
                    </Modal>
                </SpaceBetween>
            }
        >
            Parts
        </Header>
    );
}
