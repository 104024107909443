import React, { useState, useEffect } from 'react';
import { ATTACHMENT_COLUMN_DEFINITIONS } from '../../config/details-config';
import {
    Header,
    SpaceBetween,
    Box,
    Button,
    Modal,
    Table,
    FormField,
    FileUpload
} from '@amzn/awsui-components-react';
import { getHeaderCounterText, getData } from '../../utils/commons';
import { Attachment } from '../types';

export default function AttachmentsTable() {
    const [attachments, setAttachments] = useState<Attachment[]>([]);
    const [attachmentsLoading, setAttachmentsLoading] = useState<boolean>(true);
    const [selectedItems, setSelectedItems] = useState<Attachment[]>([]);
    const [uploadModalVisiable, setUploadModalVisible] = useState<boolean>(false);
    const [file, setFile] = useState<File[]>([]);
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const isOnlyOneSelected: boolean = selectedItems.length === 1;
    const atLeastOneSelected: boolean = selectedItems.length > 0;

    // fetch attachments after render of the component
    useEffect(() => {
        getData<Attachment>('attachments', (attachments) => {
            setAttachments(attachments);
            setAttachmentsLoading(false);
        });
    }, []);

    return (
        <>
            <Table
                enableKeyboardNavigation={true}
                columnDefinitions={ATTACHMENT_COLUMN_DEFINITIONS}
                loading={attachmentsLoading}
                loadingText='Loading Attachments..'
                items={attachments}
                selectionType='multi'
                selectedItems={selectedItems}
                onSelectionChange={(event) => setSelectedItems(event.detail.selectedItems)}
                header={
                    <Header
                        counter={
                            attachmentsLoading
                                ? ''
                                : getHeaderCounterText(attachments, selectedItems)
                        }
                        actions={
                            <SpaceBetween direction='horizontal' size='xs'>
                                <Button disabled={!atLeastOneSelected}>Delete</Button>
                                <Button onClick={() => setUploadModalVisible(true)}>Upload</Button>
                            </SpaceBetween>
                        }
                    >
                        Attachments
                    </Header>
                }
            />
            <Modal
                onDismiss={() => setUploadModalVisible(false)}
                visible={uploadModalVisiable}
                footer={
                    <Box float='right'>
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Button variant='link' onClick={() => setUploadModalVisible(false)}>
                                Cancel
                            </Button>
                            <Button variant='primary' onClick={() => setUploadModalVisible(false)}>
                                Ok
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header='Upload Files'
            >
                <SpaceBetween direction='vertical' size='l'>
                    <FormField
                        label='Supplementary Documentation'
                        description='Upload Supplementary Documentations'
                    >
                        <FileUpload
                            onChange={({ detail }) => setFile(detail.value)}
                            accept='.docx, .pdf'
                            value={file}
                            multiple={true}
                            i18nStrings={{
                                uploadButtonText: (e) => (e ? 'Choose files' : 'Choose file'),
                                dropzoneText: (e) =>
                                    e ? 'Drop files to upload' : 'Drop file to upload',
                                removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                                limitShowFewer: 'Show fewer files',
                                limitShowMore: 'Show more files',
                                errorIconAriaLabel: 'Error'
                            }}
                            showFileLastModified
                            showFileSize
                            showFileThumbnail
                            tokenLimit={3}
                            constraintText='Upload file as *.docx or *.pdf.'
                        />
                    </FormField>
                </SpaceBetween>
            </Modal>
        </>
    );
}
