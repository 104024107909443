import React, { useState } from 'react';
import {
    Container,
    Header,
    Input,
    FormField,
    SpaceBetween,
    FileUpload,
    Autosuggest,
    Toggle
} from '@amzn/awsui-components-react';

export default function CreateSpecificationPage() {
    const [selectedSpec, setSelectedSpec] = useState('');
    const [value, setValue] = React.useState<File[]>([]);
    const [checked, setChecked] = React.useState(true);

    return (
        <Container
            id='distribution-panel'
            header={<Header variant='h2'>Create Specification</Header>}
        >
            <SpaceBetween size='l'>
                <Toggle onChange={({ detail }) => setChecked(detail.checked)} checked={checked}>
                    Create a new specification (turn it off to find an existing specification)
                </Toggle>
                {checked ? (
                    <>
                        <FormField label='Name' stretch={true}>
                            <Input value='' onChange={() => {}} />
                        </FormField>
                        <FormField label='Description' stretch={true}>
                            <Input value='' onChange={() => {}} />
                        </FormField>
                        <FormField label='Lifecycle Phase' stretch={true}>
                            <Input value='' onChange={() => {}} />
                        </FormField>

                        <FormField label='Upload File' description='Upload File.'>
                            <FileUpload
                                multiple={true}
                                showFileSize={true}
                                showFileLastModified={true}
                                accept='.csv, .pdf'
                                value={value}
                                tokenLimit={3}
                                onChange={({ detail }) => setValue(detail.value)}
                                ariaRequired={true}
                                constraintText='Upload file as *.csv or *.pdf.'
                                i18nStrings={{
                                    uploadButtonText: (multiple) =>
                                        multiple ? 'Choose files' : 'Choose file',
                                    dropzoneText: (multiple) =>
                                        multiple ? 'Drop files to upload' : 'Drop file to upload',
                                    removeFileAriaLabel: (fileIndex) =>
                                        `Remove file ${fileIndex + 1}`,
                                    limitShowFewer: 'Show fewer files',
                                    limitShowMore: 'Show more files',
                                    errorIconAriaLabel: 'Error'
                                }}
                            />
                        </FormField>
                    </>
                ) : (
                    <FormField label='Add from existed specifications' stretch={true}>
                        <Autosuggest
                            onChange={({ detail }) => setSelectedSpec(detail.value)}
                            value={selectedSpec}
                            options={[
                                { value: 'Suggestion 1' },
                                { value: 'Suggestion 2' },
                                { value: 'Suggestion 3' },
                                { value: 'Suggestion 4' }
                            ]}
                            ariaLabel='Autosuggest example with suggestions'
                            placeholder='Enter value'
                            empty='No matches found'
                        />
                    </FormField>
                )}
            </SpaceBetween>
        </Container>
    );
}
