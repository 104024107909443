import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PartsTablePage from './parts/PartsTablePage';
import PartDetailsPage from './partDetails/PartDetailsPage';
import MappingPage from './MappingPage';
import SettingsPage from './SettingsPage';
import MissingSubstancesPage from './MissingSubstancesPage';
import AddSinglePartPage from './parts/AddSinglePartPage';
import AddMultiplePartsPage from './parts/AddMultiplePartsPage';
import { useSelector } from 'react-redux';
import '@amzn/awsui-global-styles/polaris.css';
import InvalidAuthPage from './auth/InvalidAuthPage';
import SignInLoadingPage from './auth/SignInLoadingPage';
import { AuthStatus } from '../state/authSlice';
import { RootState } from '../state/store';

export default function App() {
    const authStatus = useSelector((state: RootState) => state.auth.authStatus);

    if (authStatus === AuthStatus.invalid_profile) {
        return <InvalidAuthPage />;
    }
    if (authStatus === AuthStatus.configuring) {
        return <SignInLoadingPage />;
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Navigate to='/parts' />} />
                <Route path='/parts' element={<PartsTablePage />} />
                <Route path='/parts/add-a-part' element={<AddSinglePartPage />} />
                <Route
                    path='/parts/import-multiple-parts-from-CSV'
                    element={<AddMultiplePartsPage />}
                />
                <Route path='/parts/*' element={<PartDetailsPage />} />
                <Route path='/mapping' element={<MappingPage />} />
                <Route path='/settings' element={<SettingsPage />} />
                <Route path='/missing-substances' element={<MissingSubstancesPage />} />
                <Route path='/logout' />
            </Routes>
        </BrowserRouter>
    );
}
