import axios from 'axios';
import { Stage } from './amplify-config';
export interface IAppSettings {
    readonly apiUrl: string;
    readonly assumeRoleArn: string;
    readonly stage: Stage;
}

let cache: IAppSettings = {
    apiUrl: '',
    assumeRoleArn: '',
    stage: Stage.BETA
};

export function getAppSetting(key: string): string {
    return cache[key];
}

// This needs to be a function. If declared as constant, the cache stays with empty values
export const AppSettings = (): IAppSettings => cache;

export async function initializeAppSettings(): Promise<IAppSettings> {
    try {
        const appSettings = (await axios('/settings.json')).data;
        cache = { ...appSettings };
        Object.freeze(cache);
        return cache;
    } catch (error) {
        console.error('Failed to fetch settings json file:', error);
        throw error;
    }
}
