import React from 'react';
import CustomAppLayout from './common/CustomAppLayout';
import { SpaceBetween, Button, FormField, Input } from '@amzn/awsui-components-react';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import Breadcrumbs from './common/Breadcrumbs';

export default function SettingsPage() {
    return (
        <CustomAppLayout
            breadcrumbs={
                <Breadcrumbs
                    items={[
                        {
                            text: 'Home',
                            href: '/parts'
                        },
                        {
                            text: 'Settings',
                            href: '/Settings'
                        }
                    ]}
                />
            }
            content={<SettingsForms />}
        />
    );
}

const SettingsForms = () => {
    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <Container
                header={
                    <Header
                        actions={
                            <SpaceBetween direction='horizontal' size='xs'>
                                <Button variant='primary'>Save Settings</Button>
                            </SpaceBetween>
                        }
                        variant='h1'
                    >
                        Settings
                    </Header>
                }
            >
                <SpaceBetween size='l'>
                    <Container
                        header={
                            <Header variant='h3' headingTagOverride='h3'>
                                Agile Connection Information
                            </Header>
                        }
                    >
                        <SpaceBetween size='l'>
                            <FormField label='Agile User Name'>
                                <Input value='' onChange={() => {}} />
                            </FormField>
                            <FormField label='Agile Password'>
                                <Input value='' onChange={() => {}} />
                            </FormField>
                        </SpaceBetween>
                    </Container>
                    <Container header={<Header variant='h3'>Z2Data Connection Information</Header>}>
                        <SpaceBetween size='l'>
                            <FormField label='Z2Data API URL'>
                                <Input value='' onChange={() => {}} />
                            </FormField>
                            <FormField label='Z2Data API Key'>
                                <Input value='' onChange={() => {}} />
                            </FormField>
                        </SpaceBetween>
                    </Container>
                </SpaceBetween>
            </Container>
        </form>
    );
};
