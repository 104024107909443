import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BreadcrumbGroup, BreadcrumbGroupProps } from '@amzn/awsui-components-react';

interface BreadcrumbsProps {
    items: BreadcrumbGroupProps.Item[];
}

export default function Breadcrumbs({ items }: BreadcrumbsProps) {
    const navigate = useNavigate();
    // If the provided link is empty, do not redirect pages
    function onFollowHandler(ev) {
        ev.preventDefault();
        if (ev.detail.href) {
            const href = ev.detail.href;
            if (href) {
                navigate(href, { replace: true });
            }
        }
    }
    return <BreadcrumbGroup items={items} onFollow={onFollowHandler} />;
}
