import React from 'react';
import { Header, Link, Container, FormField, FileUpload } from '@amzn/awsui-components-react';
import Papa from 'papaparse';
import { CSVField, ParsedData } from '../types';

interface ImportCSVFileContainerProps {
    files: File[];
    setFiles: React.Dispatch<React.SetStateAction<File[]>>;
    setParsedData: React.Dispatch<React.SetStateAction<ParsedData[]>>;
    setCSVFields: React.Dispatch<React.SetStateAction<CSVField[]>>;
    displayError: boolean;
    setDisplayError: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function ImportCSVFileContainer({
    files,
    setFiles,
    setParsedData,
    setCSVFields,
    displayError,
    setDisplayError
}: ImportCSVFileContainerProps) {
    function fileOnChangehandler({ detail }) {
        setFiles(detail.value);
        const file = detail.value[0];
        if (file) {
            setDisplayError(false);
            Papa.parse(file, {
                header: true,
                complete: function (results) {
                    // Add a unique id to each parsed data item to addresses the React warning: "Each child in a list should have a unique 'key' prop"
                    const dataWithIds = results.data.map((item, index) => ({
                        ...item,
                        id: `row-${index + 1}` // This creates a unique id like 'row-1', 'row-2', etc.
                    }));
                    setParsedData(dataWithIds);
                    if (results.meta.fields.length > 0) {
                        const fieldsWithSample = results.meta.fields.map((key) => {
                            return {
                                label: key,
                                value: key,
                                tags: results.data.slice(0, 2).map((item) => item[key])
                            };
                        });
                        setCSVFields([
                            {
                                label: 'None',
                                value: 'None'
                            },
                            ...fieldsWithSample
                        ]);
                    }
                }
            });
        } else {
            setCSVFields([]);
            setDisplayError(true);
            setParsedData([]);
        }
    }
    return (
        <Container
            header={
                <Header
                    variant='h2'
                    description='Upload a CSV file containing part information. Required fields are Manufacturer Part Number and Manufacturer.'
                >
                    Import Multiple Parts from CSV
                </Header>
            }
        >
            <FormField
                label='Import Parts'
                info={
                    <Link variant='info' href='/PartsCSVExample.csv'>
                        Example CSV File
                    </Link>
                }
            >
                <FileUpload
                    showFileSize={true}
                    showFileLastModified={true}
                    accept='.csv'
                    value={files}
                    tokenLimit={3}
                    onChange={fileOnChangehandler}
                    ariaRequired={true}
                    constraintText='Upload file as *.csv.'
                    errorText={displayError && 'CSV file is required.'}
                    i18nStrings={{
                        uploadButtonText: (multiple) => (multiple ? 'Choose files' : 'Choose file'),
                        dropzoneText: (multiple) =>
                            multiple ? 'Drop files to upload' : 'Drop file to upload',
                        removeFileAriaLabel: (fileIndex) => `Remove file ${fileIndex + 1}`,
                        limitShowFewer: 'Show fewer files',
                        limitShowMore: 'Show more files',
                        errorIconAriaLabel: 'Error'
                    }}
                />
            </FormField>
        </Container>
    );
}
