import React, { useState, useEffect } from 'react';
import { Header, SpaceBetween, Table } from '@amzn/awsui-components-react';
import { getHeaderCounterText, getData } from '../../utils/commons';
import { Audit } from '../types';
import { AUDIT_COLUMN_DEFINITIONS } from '../../config/details-config';

export default function AuditTrailTable() {
    const [audits, setAudits] = useState<Audit[]>([]);
    const [auditsLoading, setAuditsLoading] = useState<boolean>(true);
    const [selectedItems, setSelectedItems] = useState<Audit[]>([]);

    // fetch audits after render of the component
    useEffect(() => {
        getData<Audit>('audits', (audits) => {
            setAudits(audits);
            setAuditsLoading(false);
        });
    }, []);

    return (
        <Table
            enableKeyboardNavigation={true}
            className='Audit-table'
            columnDefinitions={AUDIT_COLUMN_DEFINITIONS}
            loading={auditsLoading}
            loadingText='Loading Audit Trail'
            items={audits}
            onSelectionChange={(event) => setSelectedItems(event.detail.selectedItems)}
            header={
                <Header
                    counter={auditsLoading ? '' : getHeaderCounterText(audits, selectedItems)}
                    actions={<SpaceBetween direction='horizontal' size='xs'></SpaceBetween>}
                >
                    Audit Trail
                </Header>
            }
        />
    );
}
