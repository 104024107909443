import React from 'react';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { TableProps } from '@amzn/awsui-components-react';

export const getHeaderCounterText = <T>(items: Array<T>, selectedItems: Array<T>): string => {
    return selectedItems && selectedItems?.length > 0
        ? `(${selectedItems.length}/${items.length})`
        : `(${items.length})`;
};

export const getTextFilterCounterServerSideText = <T>(
    items: T[],
    pagesCount: number,
    pageSize: number
): string => {
    const count = pagesCount > 1 ? `${pageSize * (pagesCount - 1)}+` : items.length + '';
    return count === '1' ? `1 match` : `${count} matches`;
};

export function getStringFromReactNode(node: React.ReactNode): string {
    if (typeof node === 'string') {
        return node;
    }
    if (typeof node === 'number') {
        return node.toString();
    }
    if (node instanceof Array) {
        return node.map(getStringFromReactNode).join('');
    }
    if (typeof node === 'object' && node !== null && 'props' in node) {
        const reactElement = node as React.ReactElement;
        if (typeof reactElement.props.children === 'string') {
            return reactElement.props.children;
        }
        if (reactElement.props.children instanceof Array) {
            return reactElement.props.children.map(getStringFromReactNode).join('');
        }
    }
    return '';
}

export const generateHeadersAndRows = <T>(
    data: T[],
    columnDefinitions: TableProps.ColumnDefinition<T>[]
): { headers: string[]; rows: (string | number | boolean | null)[][] } => {
    const headers: string[] = columnDefinitions.map((column) =>
        getStringFromReactNode(column.header)
    );
    const rows: (string | number | boolean | null)[][] = data.map((row) =>
        columnDefinitions.map((column) => (column.id ? row[column.id] ?? '' : ''))
    );
    return { headers, rows };
};

export const exportDataToXLSX = <T>(
    data: T[],
    fileName: string,
    columnDefinitions: TableProps.ColumnDefinition<T>[]
) => {
    const { headers, rows } = generateHeadersAndRows(data, columnDefinitions);
    const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

export const exportDataToCSV = <T>(
    type: string,
    data: T[],
    fileName: string,
    columnDefinitions: TableProps.ColumnDefinition<T>[]
) => {
    const { headers, rows } = generateHeadersAndRows(data, columnDefinitions);
    const csvString = Papa.unparse({ fields: headers, data: rows });
    const blob = new Blob([csvString], { type: `${type};charset=utf-8;` });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const exportDataToJson = <T>(
    type: string,
    data: Array<T>,
    fileName: string,
    columnDefinitions: TableProps.ColumnDefinition<T>[]
) => {
    const newData = data.map((row) => {
        const newRow = {};
        for (const column of columnDefinitions) {
            newRow[getStringFromReactNode(column.header)] = column.id ? row[column.id] ?? ' ' : ' ';
        }
        return newRow;
    });
    const fileData = JSON.stringify(newData);
    const blob = new Blob([fileData], { type: type });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export function getData<T>(name: string, callback: (_data: T[]) => void): void {
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
            callback(JSON.parse(this.responseText) as T[]);
        }
    };
    xhttp.open('GET', `/${name}.json`, true);
    xhttp.send();
}

// Helper function to extract error message
export const getErrorMessage = (error: unknown): string => {
    if (error instanceof Error) return error.message;
    return String(error);
};
