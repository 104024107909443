/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import CustomAppLayout from '../common/CustomAppLayout';
import {
    Header,
    SpaceBetween,
    Box,
    Button,
    Modal,
    Container,
    KeyValuePairs,
    StatusIndicator,
    FormField,
    Input,
    FileUpload,
    RadioGroup
} from '@amzn/awsui-components-react';
import Tabs from '@amzn/awsui-components-react/polaris/tabs';
import BillOfMaterialsTable from './BillOfMaterialsTable';
import ComplianceTable from './ComplianceTable';
import { CreateNewPage } from '../common/CreateNewPage';
import CreateSpecificationPage from './CreateSpecificationPage';
import AuditTrailTable from './AuditTrailTable';
import AttachmentsTable from './AttachmentsTable';
import EditSpecificationPage from './EditSpecificationPage';
import Breadcrumbs from '../common/Breadcrumbs';
import { StatusIndicatorType } from '../types';

// eslint-disable-next-line react/prop-types
const GeneralConfig = ({ uploadedComplianceReviewStatus }) => (
    <Container header={<Header variant='h2'>General Information</Header>}>
        <KeyValuePairs
            columns={5}
            items={[
                {
                    label: 'Description',
                    value: '100 - Integrated Circuit • FLASH 128Mb SPI sec=256kB SO16'
                },
                {
                    label: 'Compliance Status',
                    value: (
                        <StatusIndicator type={StatusIndicatorType.SUCCESS}>
                            Compliant
                        </StatusIndicator>
                    )
                }
                // Temporarily commented out per mock UI feedback (Date: 2024-10-4). May be reintroduced based on future requirements.
                // {
                //     label: 'Uploaded Compliance Data',
                //     value: (
                //         <>
                //             <StatusIndicator
                //                 type={
                //                     uploadedComplianceReviewStatus === 'Pending'
                //                         ? 'pending'
                //                         : uploadedComplianceReviewStatus === 'Approved'
                //                         ? 'success'
                //                         : 'error'
                //                 }
                //             >
                //                 {uploadedComplianceReviewStatus === 'Pending'
                //                     ? 'Waiting Review'
                //                     : uploadedComplianceReviewStatus}
                //             </StatusIndicator>
                //             {' • '}
                //             <Link href='/IPC1752A.pdf' target='_blank'>
                //                 IPC1752A.pdf
                //             </Link>{' '}
                //         </>
                //     )
                // },
                // {
                //     label: 'Disclosed Status',
                //     value: <StatusIndicator type='warning'>Partially Disclosed</StatusIndicator>
                // }
            ]}
        />
    </Container>
);

export default function PartDetailsPage() {
    const { '*': path } = useParams();
    const location = useLocation();
    const ids = location.pathname.split('/').filter((id) => id !== 'home' && id !== '');
    const [retriModalVisible, setRetriModalVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [uploadModalVisiable, setUploadModalVisible] = useState(false);
    const [file, setFile] = React.useState<File[]>([]);
    const [complianceStatus, setComplianceStatus] = useState('first');
    // Radio group selection on the modal
    const [uploadedComplianceStatus, setUploadedComplianceStatus] = useState('first');
    // Approval or Reject on the buttondropdown
    const [uploadedComplianceReviewStatus, setUploadedComplianceReviewStatus] = useState('Pending');
    const [activeTabId, setActiveTabId] = useState('Compliance');
    const segments = path ? path.split('/') : [];
    const tabs = [
        {
            label: 'Compliance',
            id: 'Compliance',
            content: <ComplianceTable />
        },
        {
            label: 'BOM',
            id: 'bom',
            content: <BillOfMaterialsTable ids={ids} />
        },
        {
            label: 'Audit',
            id: 'audit',
            content: <AuditTrailTable />
        },
        {
            label: 'Attachments',
            id: 'Attachments',
            content: <AttachmentsTable />
        }
    ];

    const DetailPageContent = () => {
        const url = segments[segments.length - 1];
        const [files, setFiles] = useState([]);
        switch (url) {
            case 'create-specification':
                return <CreateNewPage Panel={<CreateSpecificationPage />} buttonText='Confirm' />;
            case 'edit-specification':
                return <CreateNewPage Panel={<EditSpecificationPage />} />;
            default:
                return (
                    <SpaceBetween size='m'>
                        <Header
                            variant='h1'
                            actions={
                                <SpaceBetween direction='horizontal' size='xs'>
                                    {/* Temporarily commented out per mock UI feedback (Date:
                                    2024-10-4). May be reintroduced based on future requirements.
                                    <ButtonDropdown
                                        items={[
                                            {
                                                text: 'Reject',
                                                id: 'reject',
                                                disabled: false
                                            },
                                            {
                                                text: 'Approval',
                                                id: 'approval',
                                                disabled: false
                                            }
                                        ]}
                                        key='Review'
                                        onItemClick={(e) => {
                                            const id = e.detail.id;
                                            switch (id) {
                                                case 'reject':
                                                    setUploadedComplianceReviewStatus('Reject');
                                                    break;
                                                case 'approval':
                                                    setUploadedComplianceReviewStatus('Approved');
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }}
                                    >
                                        {'Review'}
                                    </ButtonDropdown>
                                    <Button
                                        key='upload'
                                        onClick={() => setUploadModalVisible(true)}
                                    >
                                        Upload
                                    </Button> */}
                                </SpaceBetween>
                            }
                        >
                            {ids[ids.length - 1]}
                        </Header>
                        <SpaceBetween size='l'>
                            <GeneralConfig
                                uploadedComplianceReviewStatus={uploadedComplianceReviewStatus}
                            />
                            <Tabs
                                tabs={tabs}
                                activeTabId={activeTabId}
                                ariaLabel='Resource details'
                                onChange={({ detail }) => {
                                    setActiveTabId(detail.activeTabId);
                                }}
                            />
                            <Modal
                                onDismiss={() => setRetriModalVisible(false)}
                                visible={retriModalVisible}
                                footer={
                                    <Box float='right'>
                                        <SpaceBetween direction='horizontal' size='xs'>
                                            <Button
                                                variant='link'
                                                onClick={() => setRetriModalVisible(false)}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant='primary'
                                                onClick={() => setRetriModalVisible(false)}
                                            >
                                                Ok
                                            </Button>
                                        </SpaceBetween>
                                    </Box>
                                }
                                header='Add Compliance Retriever'
                            >
                                <SpaceBetween direction='vertical' size='l'>
                                    <FormField label='Part Id'>
                                        <Input value={ids[ids.length - 1]} readOnly />
                                    </FormField>
                                    <FormField label='Retrieval Source'>
                                        <Input value='' onChange={() => {}} />
                                    </FormField>
                                </SpaceBetween>
                            </Modal>
                            <Modal
                                onDismiss={() => setEditModalVisible(false)}
                                visible={editModalVisible}
                                footer={
                                    <Box float='right'>
                                        <SpaceBetween direction='horizontal' size='xs'>
                                            <Button
                                                variant='link'
                                                onClick={() => setEditModalVisible(false)}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant='primary'
                                                onClick={() => setEditModalVisible(false)}
                                            >
                                                Ok
                                            </Button>
                                        </SpaceBetween>
                                    </Box>
                                }
                                header='Edit Details'
                            >
                                <SpaceBetween direction='vertical' size='l'>
                                    <FormField label='Update compliance status'>
                                        <RadioGroup
                                            onChange={({ detail }) =>
                                                setComplianceStatus(detail.value)
                                            }
                                            value={complianceStatus}
                                            items={[
                                                {
                                                    value: 'first',
                                                    label: 'Complianct'
                                                },
                                                {
                                                    value: 'second',
                                                    label: 'Not-Complianct'
                                                },
                                                {
                                                    value: 'third',
                                                    label: 'Unknown'
                                                }
                                            ]}
                                        />
                                    </FormField>
                                    <FormField label='Reivew uploaded compliance data'>
                                        <RadioGroup
                                            onChange={({ detail }) =>
                                                setUploadedComplianceStatus(detail.value)
                                            }
                                            value={uploadedComplianceStatus}
                                            items={[
                                                {
                                                    value: 'first',
                                                    label: 'Penging'
                                                },
                                                {
                                                    value: 'second',
                                                    label: 'Approved'
                                                },
                                                {
                                                    value: 'third',
                                                    label: 'Reject'
                                                }
                                            ]}
                                        />
                                    </FormField>
                                </SpaceBetween>
                            </Modal>
                            <Modal
                                onDismiss={() => setUploadModalVisible(false)}
                                visible={uploadModalVisiable}
                                footer={
                                    <Box float='right'>
                                        <SpaceBetween direction='horizontal' size='xs'>
                                            <Button
                                                variant='link'
                                                onClick={() => setUploadModalVisible(false)}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant='primary'
                                                onClick={() => setUploadModalVisible(false)}
                                            >
                                                Ok
                                            </Button>
                                        </SpaceBetween>
                                    </Box>
                                }
                                header='Upload Compliance Data'
                            >
                                <SpaceBetween direction='vertical' size='l'>
                                    <FormField
                                        label='Upload Compliance Data'
                                        description='Upload Compliance Data'
                                    >
                                        <FileUpload
                                            onChange={({ detail }) => setFile(detail.value)}
                                            value={file}
                                            accept='.pdf'
                                            i18nStrings={{
                                                uploadButtonText: (e) =>
                                                    e ? 'Choose files' : 'Choose file',
                                                dropzoneText: (e) =>
                                                    e
                                                        ? 'Drop files to upload'
                                                        : 'Drop file to upload',
                                                removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                                                limitShowFewer: 'Show fewer files',
                                                limitShowMore: 'Show more files',
                                                errorIconAriaLabel: 'Error'
                                            }}
                                            showFileLastModified
                                            showFileSize
                                            showFileThumbnail
                                            tokenLimit={3}
                                            constraintText='Upload file as *.pdf.'
                                        />
                                    </FormField>
                                </SpaceBetween>
                            </Modal>
                        </SpaceBetween>
                    </SpaceBetween>
                );
        }
    };

    const pathArr = location.pathname
        .split('/')
        .filter((item) => item !== '')
        .map((curr, i, array) => {
            if (curr === 'parts') {
                curr = 'Home';
            }
            return {
                text: curr,
                href: `/${array.slice(0, i + 1).join('/')}`
            };
        });

    return (
        <CustomAppLayout
            content={<DetailPageContent />}
            breadcrumbs={<Breadcrumbs items={pathArr} />}
        />
    );
}
