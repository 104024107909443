export enum Stage {
    DEV = 'dev',
    BETA = 'beta',
    GAMMA = 'gamma',
    PROD = 'prod'
}
export enum ResponseType {
    CODE = 'code',
    TOKEN = 'token'
}
export interface AmplifyConfigInterface {
    readonly userPoolId: string;
    readonly identityPoolId: string;
    readonly oauthDomain: string;
    readonly oauthClientId: string;
    readonly responseType: ResponseType;
    readonly scopes: string[];
    readonly redirectUrls: string[];
    readonly grantType: string;
    readonly identityProviderName: string;
}

export const PROVIDER_NAME = 'AmazonFederate';
export const SCOPES = ['openid'];
export const GRANTTYPE = 'authorization_code';

export const amplifyConfig: Record<string, AmplifyConfigInterface> = {
    [Stage.DEV]: {
        userPoolId: 'us-east-1_3Gt7bj2oK',
        identityPoolId: 'us-east-1:f438d1ec-7b2c-4bad-a570-2840ca9ba67a',
        oauthDomain: 'compliancecrow-beta.auth.us-east-1.amazoncognito.com',
        oauthClientId: '41j6b64kp39ksrtje6fau0jknb',
        responseType: ResponseType.CODE,
        scopes: SCOPES,
        redirectUrls: ['https://compliancecrow.amazon.com:4443'],
        grantType: GRANTTYPE,
        identityProviderName: PROVIDER_NAME
    },
    [Stage.BETA]: {
        userPoolId: 'us-east-1_3Gt7bj2oK',
        identityPoolId: 'us-east-1:f438d1ec-7b2c-4bad-a570-2840ca9ba67a',
        oauthDomain: 'compliancecrow-beta.auth.us-east-1.amazoncognito.com',
        oauthClientId: '41j6b64kp39ksrtje6fau0jknb',
        responseType: ResponseType.CODE,
        scopes: SCOPES,
        redirectUrls: ['https://beta.compliancecrow.birds.amazon.dev'],
        grantType: GRANTTYPE,
        identityProviderName: PROVIDER_NAME
    },
    [Stage.GAMMA]: {
        userPoolId: '',
        identityPoolId: '',
        oauthDomain: '',
        oauthClientId: '',
        responseType: ResponseType.CODE,
        scopes: SCOPES,
        redirectUrls: ['https://gamma.compliancecrow.birds.amazon.dev'],
        grantType: GRANTTYPE,
        identityProviderName: PROVIDER_NAME
    },
    [Stage.PROD]: {
        userPoolId: '',
        identityPoolId: '',
        oauthDomain: '',
        oauthClientId: '',
        responseType: ResponseType.CODE,
        scopes: SCOPES,
        redirectUrls: ['https://prod.compliancecrow.birds.amazon.dev'],
        grantType: GRANTTYPE,
        identityProviderName: PROVIDER_NAME
    }
};
