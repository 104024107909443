/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import ServiceNavigation from './ServiceNavigation';
import { AppLayout, TopNavigation, AppLayoutProps } from '@amzn/awsui-components-react';
import { signOut } from 'aws-amplify/auth';

interface CustomAppLayoutProps {
    notifications?: React.ReactNode;
    breadcrumbs?: React.ReactNode;
    content?: React.ReactNode;
    toolsHide?: boolean;
    navigationHide?: boolean;
    contentType?: AppLayoutProps.ContentType;
    signoutHide?: boolean;
}

export default function CustomAppLayout({
    notifications = null,
    breadcrumbs,
    content,
    toolsHide = true,
    navigationHide = false,
    contentType = 'table',
    signoutHide = false,
    ...props
}: CustomAppLayoutProps) {
    const handleSignOut = async (e: CustomEvent) => {
        e.preventDefault(); // Prevent default link behavior
        try {
            await signOut();
        } catch (error) {
            console.error('Error signing out: ', error);
        }
    };
    const signoutButton = [
        {
            type: 'button',
            text: 'Sign out',
            title: 'Sign out',
            ariaLabel: 'Sign out',
            onClick: (e) => handleSignOut(e)
        }
    ];
    return (
        <>
            <TopNavigation
                identity={{
                    href: '/parts',
                    title: 'Compliance Crow'
                }}
                utilities={[]}
            />
            <AppLayout
                {...props}
                notifications={notifications}
                navigation={navigationHide ? null : <ServiceNavigation />}
                breadcrumbs={breadcrumbs}
                content={content}
                contentType={contentType}
                toolsHide={toolsHide}
            />
        </>
    );
}
