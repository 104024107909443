import React, { useState } from 'react';
import {
    Container,
    Header,
    RadioGroup,
    FormField,
    SpaceBetween
} from '@amzn/awsui-components-react';
export default function EditSpecificationPage() {
    const [complianceStatus, setComplianceStatus] = useState('second');
    return (
        <Container header={<Header variant='h2'>Edit Specification</Header>}>
            <SpaceBetween size='l'>
                <FormField label='Calculated Compliance' stretch={true}>
                    <RadioGroup
                        onChange={({ detail }) => setComplianceStatus(detail.value)}
                        value={complianceStatus}
                        items={[
                            {
                                value: 'first',
                                label: 'Compliant'
                            },
                            {
                                value: 'second',
                                label: 'Non-Compliant'
                            },
                            {
                                value: 'third',
                                label: 'Unknown'
                            }
                        ]}
                    />
                </FormField>
            </SpaceBetween>
        </Container>
    );
}
