import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FlashbarProps } from '@amzn/awsui-components-react';

export interface FlashMessageState {
    items: FlashbarProps.MessageDefinition[];
}

const initialState: FlashMessageState = {
    items: []
};

export const flashMessageSlice = createSlice({
    name: 'flashMessage',
    initialState,
    reducers: {
        addFlashMessage: (state, action: PayloadAction<FlashbarProps.MessageDefinition>) => {
            state.items.unshift(action.payload);
        },
        removeFlashMessage: (state, action: PayloadAction<string>) => {
            state.items = state.items.filter((item) => item.id !== action.payload);
        }
    }
});

export const { addFlashMessage, removeFlashMessage } = flashMessageSlice.actions;

export default flashMessageSlice.reducer;
