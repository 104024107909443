import React, { useState, useEffect } from 'react';
import CustomAppLayout from './common/CustomAppLayout';
import {
    Header,
    Table,
    Pagination,
    StatusIndicator,
    Input,
    SpaceBetween,
    SegmentedControl,
    TableProps
} from '@amzn/awsui-components-react';
import { getData } from '../utils/commons';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Breadcrumbs from './common/Breadcrumbs';
import {
    StatusIndicatorType,
    MissingSubstance,
    SubstanceState,
    SubstanceAgileEditOption,
    SubstanceAgileEditInputType
} from './types';

export default function MissingSubstancesPage() {
    return (
        <CustomAppLayout
            breadcrumbs={
                <Breadcrumbs
                    items={[
                        {
                            text: 'Home',
                            href: '/parts'
                        },
                        {
                            text: 'Missing Substances',
                            href: '/missing-substances'
                        }
                    ]}
                />
            }
            content={<MissingSubstancesTable />}
        />
    );
}

const MissingSubstancesTable = () => {
    const [missingSubstances, setMissingSubstances] = useState<MissingSubstance[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        getData<MissingSubstance>('missingSubstances', (missingSubstances) => {
            setMissingSubstances(
                missingSubstances.filter((item) => item.status === SubstanceState.MISSING)
            );
            setLoading(false);
        });
    }, []);

    const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<MissingSubstance>[] = [
        {
            id: 'substance',
            cell: (item) => item.name,
            header: 'Substance',
            maxWidth: '200px'
        },
        {
            id: 'agileMapping',
            cell: (item) => item.agileName,
            header: 'Agile Mapping',
            minWidth: '140px',
            editConfig: {
                ariaLabel: 'Agile Mapping',
                editIconAriaLabel: 'editable',
                errorIconAriaLabel: 'Agile Mapping Error',
                editingCell: (item, { currentValue, setValue }) => {
                    const currentValueWithDefault = {
                        inputValue: currentValue ? currentValue.inputValue : item.agileName,
                        controlId: currentValue
                            ? currentValue.controlId
                            : SubstanceAgileEditOption.NEW
                    };
                    return (
                        <SpaceBetween size='l'>
                            <Input
                                autoFocus={true}
                                value={currentValueWithDefault.inputValue}
                                onChange={(event) =>
                                    setValue({
                                        ...currentValueWithDefault,
                                        inputValue: event.detail.value
                                    })
                                }
                            />
                            <SegmentedControl
                                selectedId={currentValueWithDefault.controlId}
                                onChange={(event) =>
                                    setValue({
                                        ...currentValueWithDefault,
                                        controlId: event.detail.selectedId
                                    })
                                }
                                label='Default segmented control'
                                options={[
                                    {
                                        text: SubstanceAgileEditOption.NEW,
                                        id: SubstanceAgileEditOption.NEW
                                    },
                                    {
                                        text: SubstanceAgileEditOption.ALIAS,
                                        id: SubstanceAgileEditOption.ALIAS
                                    }
                                ]}
                            />
                        </SpaceBetween>
                    );
                }
            }
        },
        {
            id: 'casNumber',
            cell: (item) => item.casNumber,
            header: 'CAS Number',
            maxWidth: '120px'
        },
        {
            id: 'agileFMD',
            cell: (item) => item.agileFmd,
            header: 'Agile FMD',
            maxWidth: '100px'
        },
        {
            id: 'status',
            cell: (item) => (
                <StatusIndicator
                    type={
                        item.status === SubstanceState.MISSING
                            ? StatusIndicatorType.ERROR
                            : StatusIndicatorType.SUCCESS
                    }
                >
                    {' '}
                    {item.status}
                </StatusIndicator>
            ),
            header: 'Status',
            minWidth: '120px'
        }
    ];

    const { items, paginationProps } = useCollection(
        missingSubstances.filter((item) => item.status === SubstanceState.MISSING),
        {
            pagination: { pageSize: 30 }
        }
    );

    const handleSubmit: TableProps.SubmitEditFunction<MissingSubstance> = async (
        currentItem,
        _column,
        value
    ) => {
        await new Promise((resolve) => setTimeout(resolve, 1500));
        const editValue = value as SubstanceAgileEditInputType;
        const newItem = { ...currentItem, agileName: editValue.inputValue };
        setMissingSubstances(
            missingSubstances.map((item) => (item.id === currentItem.id ? newItem : item))
        );
    };

    return (
        <Table
            variant='full-page'
            columnDefinitions={COLUMN_DEFINITIONS}
            items={items}
            header={
                <Header variant='h1' counter={String(missingSubstances.length)}>
                    Missing Substances
                </Header>
            }
            loading={loading}
            loadingText='Loading Missing Substances'
            pagination={<Pagination {...paginationProps} />}
            wrapLines={true}
            submitEdit={handleSubmit}
        />
    );
};
