import React, { useState } from 'react';
import {
    Container,
    Header,
    Input,
    FormField,
    SpaceBetween,
    Link,
    Button,
    Form,
    NonCancelableCustomEvent,
    InputProps,
    Flashbar,
    FlashbarProps
} from '@amzn/awsui-components-react';
import { useNavigate } from 'react-router-dom';
import CustomAppLayout from '../common/CustomAppLayout';
import Breadcrumbs from '../common/Breadcrumbs';
import { subscribePart, operationConfigs, OperationType } from './partSubscriptionUtils';
import { SubscribePartInput } from '../../open-api/generated-src';
import {
    validateEmpty,
    ValidationConfigType,
    validateField
} from '../../utils/form-validation-config';
import useConfirmationModal from '../hooks/useConfirmationModal';
import useFlashMessage from '../hooks/useFlashMessage';

export const InfoLink = (props) => (
    <Link variant='info' {...props}>
        Info
    </Link>
);

const defaultFormData = {
    manufacturer: '',
    manufacturerPartNumber: ''
};

type ErrorsType = {
    manufacturer: string;
    manufacturerPartNumber: string;
};

const defaultErrors = {
    manufacturer: '',
    manufacturerPartNumber: ''
};

const fieldsToValidate = ['manufacturer', 'manufacturerPartNumber'];
const validationConfig: ValidationConfigType = {
    manufacturer: [{ validate: validateEmpty, errorText: 'Manufacturer is required.' }],
    manufacturerPartNumber: [
        { validate: validateEmpty, errorText: 'Manufacturer Part Number is required.' }
    ]
};

export default function AddSinglePartPage() {
    const [flashMessages, setFlashMessages] = useState<FlashbarProps.MessageDefinition[]>([]);
    const navigate = useNavigate();
    const [formData, setFormData] = useState<SubscribePartInput>(defaultFormData);
    const { addFlashMessage } = useFlashMessage({ setFlashMessages });
    const [errors, setErrors] = useState<ErrorsType>(defaultErrors);
    const { ConfirmationModal, openModal } = useConfirmationModal();
    const onCancelClick = (e: CustomEvent) => {
        e.preventDefault();
        if (JSON.stringify(formData) !== JSON.stringify(defaultFormData)) {
            openModal({
                onConfirm: () => {
                    setFormData(defaultFormData);
                    setErrors(defaultErrors);
                    navigate(-1);
                }
            });
        } else {
            navigate(-1);
        }
    };
    const onSubmitHandler = async (e: React.FormEvent) => {
        e.preventDefault();
        const newErrors = { ...errors };
        fieldsToValidate.forEach((attribute) => {
            const { errorText } = validateField(
                attribute,
                validationConfig,
                formData[attribute],
                formData[attribute]
            );
            newErrors[attribute] = errorText;
        });
        setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));
        if (!newErrors.manufacturer && !newErrors.manufacturerPartNumber) {
            try {
                await subscribePart(formData);
                addFlashMessage({
                    type: 'success',
                    dismissible: true,
                    content: operationConfigs[OperationType.Subscribe].successMessageSingle
                });
                navigate(-1);
            } catch (err) {
                addFlashMessage({
                    type: 'error',
                    dismissible: true,
                    content: operationConfigs[OperationType.Subscribe].errorMessageSingle(err)
                });
            }
        }
    };

    const handleInputChange = (
        field: keyof typeof formData,
        event: NonCancelableCustomEvent<InputProps.ChangeDetail>
    ) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: event.detail.value
        }));
    };

    return (
        <CustomAppLayout
            notifications={<Flashbar items={flashMessages} stackItems />}
            breadcrumbs={
                <Breadcrumbs
                    items={[
                        {
                            text: 'Home',
                            href: '/parts'
                        },
                        {
                            text: 'Add a Part',
                            href: '/parts/add-a-part'
                        }
                    ]}
                />
            }
            contentType='form'
            content={
                <>
                    <form onSubmit={onSubmitHandler}>
                        <Form
                            actions={
                                <SpaceBetween direction='horizontal' size='xs'>
                                    <Button variant='link' onClick={onCancelClick}>
                                        Cancel
                                    </Button>
                                    <Button variant='primary'>Add</Button>
                                </SpaceBetween>
                            }
                            errorIconAriaLabel='Error'
                        >
                            <SpaceBetween size='l'>
                                <Container header={<Header variant='h2'>Add a Part</Header>}>
                                    <SpaceBetween size='l'>
                                        <FormField
                                            label='Manufacturer'
                                            errorText={errors.manufacturer}
                                        >
                                            <Input
                                                value={formData.manufacturer}
                                                onChange={(e) =>
                                                    handleInputChange('manufacturer', e)
                                                }
                                            />
                                        </FormField>
                                        <FormField
                                            label='Manufacturer Part Number'
                                            errorText={errors.manufacturerPartNumber}
                                        >
                                            <Input
                                                value={formData.manufacturerPartNumber}
                                                onChange={(e) =>
                                                    handleInputChange('manufacturerPartNumber', e)
                                                }
                                            />
                                        </FormField>
                                    </SpaceBetween>
                                </Container>
                            </SpaceBetween>
                        </Form>
                    </form>
                    <ConfirmationModal />
                </>
            }
        />
    );
}
