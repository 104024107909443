import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, SpaceBetween } from '@amzn/awsui-components-react';

interface CreateNewPageProps {
    header?: string;
    Panel: React.ReactNode;
    buttonText?: string;
    isDisabled?: boolean;
}
export function CreateNewPage({ header, Panel, buttonText, isDisabled }: CreateNewPageProps) {
    const navigate = useNavigate();
    const onCancelClick = () => {
        navigate(-1);
    };
    const onCreateClick = () => {
        navigate(-1);
    };
    return (
        <Form
            header={header}
            actions={
                <SpaceBetween direction='horizontal' size='xs'>
                    <Button variant='link' onClick={onCancelClick}>
                        Cancel
                    </Button>
                    <Button
                        data-testid='create'
                        variant='primary'
                        disabled={isDisabled}
                        onClick={onCreateClick}
                    >
                        {buttonText}
                    </Button>
                </SpaceBetween>
            }
            errorIconAriaLabel='Error'
        >
            <SpaceBetween size='l'>{Panel}</SpaceBetween>
        </Form>
    );
}
