import { fetchAuthSession, getCurrentUser, signInWithRedirect } from 'aws-amplify/auth';
import { IdTokenType } from '../state/authSlice';
import { store } from '../state/store';
import { setIdToken, setAuthStatus, AuthStatus } from '../state/authSlice';
import { AppSettings } from '../config/AppSettings';
import {
    amplifyConfig,
    AmplifyConfigInterface,
    Stage,
    PROVIDER_NAME
} from '../config/amplify-config';
import { Amplify } from 'aws-amplify';

export const configureAmplify = async () => {
    try {
        const currentStage = AppSettings().stage || Stage.DEV;
        const config: AmplifyConfigInterface = amplifyConfig[currentStage];
        Amplify.configure({
            Auth: {
                Cognito: {
                    userPoolId: config.userPoolId,
                    userPoolClientId: config.oauthClientId,
                    identityPoolId: config.identityPoolId,
                    loginWith: {
                        oauth: {
                            domain: config.oauthDomain,
                            scopes: config.scopes,
                            redirectSignIn: config.redirectUrls,
                            redirectSignOut: config.redirectUrls,
                            responseType: config.responseType,
                            providers: [{ custom: config.identityProviderName }]
                        }
                    }
                }
            }
        });
    } catch (error) {
        console.error('Failed to configure Amplify:', error);
        throw error;
    }
};

export const setupAuth = async () => {
    await configureAmplify();
    return await fetchAuthSession()
        .then((session) => {
            const idToken: IdTokenType = session.tokens?.idToken;
            if (!idToken) {
                store.dispatch(setAuthStatus(AuthStatus.unauthenticated));
                return false;
            }
            if (!('cognito:username' in idToken.payload)) {
                store.dispatch(setAuthStatus(AuthStatus.invalid_profile));
                store.dispatch(setIdToken(idToken));
                return true;
            }

            store.dispatch(setAuthStatus(AuthStatus.authenticated));
            store.dispatch(setIdToken(idToken));
            return true;
        })
        .catch((reason) => {
            console.log('Error when fetchAuthSession: ', reason);
            store.dispatch(setAuthStatus(AuthStatus.unauthenticated));
            return false;
        });
};

export const signIn = async () => {
    store.dispatch(setAuthStatus(AuthStatus.configuring));
    const providerOptions = {
        provider: {
            custom: PROVIDER_NAME
        }
    };
    signInWithRedirect(providerOptions);
};

export const getIdToken = (): IdTokenType => {
    return store.getState().auth.idToken;
};

export const getRoleSessionName = async () => {
    const currentUser = await getCurrentUser();
    return currentUser.userId;
};
