import React from 'react';
import { Flashbar, FlashbarProps } from '@amzn/awsui-components-react';
import CustomAppLayout from '../common/CustomAppLayout';

export default function InvalidAuthPage() {
    const failedAuthMessage: Array<FlashbarProps.MessageDefinition> = [
        {
            header: 'Failed to sign in using Midway',
            type: 'error',
            content: 'Please check your midway credentials and try refreshing.',
            dismissible: false
        }
    ];
    return (
        <CustomAppLayout
            notifications={<Flashbar items={failedAuthMessage} stackItems />}
            navigationHide
            signoutHide
        />
    );
}
