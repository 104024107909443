import * as React from 'react';
import Table from '@amzn/awsui-components-react/polaris/table';
import Header from '@amzn/awsui-components-react/polaris/header';
import Select from '@amzn/awsui-components-react/polaris/select';
import { Alert } from '@amzn/awsui-components-react';
import { CSVField, FieldsMappingType, FieldMatchTableType } from '../types';

interface ImportedCSVFieldsTableProps {
    csvFields: CSVField[] | undefined;
    fieldsMapping: FieldsMappingType;
    setFieldsMapping: React.Dispatch<React.SetStateAction<FieldsMappingType>>;
    displayError: boolean;
}
export default function ImportedCSVFieldsTable({
    csvFields,
    fieldsMapping,
    setFieldsMapping,
    displayError
}: ImportedCSVFieldsTableProps) {
    const fieldMatchItems: FieldMatchTableType[] = Object.keys(fieldsMapping).map((key) => ({
        attribute: key,
        csvField: fieldsMapping[key]
    }));
    return (
        <>
            <Table
                header={
                    <Header
                        variant='h2'
                        description='Match the fields in your CSV file to the corresponding Part attributes.'
                    >
                        Map CSV Fields to Part Attributes
                    </Header>
                }
                renderAriaLive={({ firstIndex, lastIndex, totalItemsCount }) =>
                    `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
                }
                ariaLabels={{
                    activateEditLabel: (column, item) => `Edit ${item.attribute} ${column.header}`,
                    cancelEditLabel: (column) => `Cancel editing ${column.header}`,
                    submitEditLabel: (column) => `Submit editing ${column.header}`,
                    tableLabel: 'Map CSV fields to Part attributes'
                }}
                columnDefinitions={[
                    {
                        id: 'attribute',
                        header: 'Part Attributes',
                        minWidth: 176,
                        cell: (item) => item.attribute,
                        isRowHeader: true
                    },
                    {
                        id: 'csvField',
                        header: 'CSV Fields',
                        minWidth: 176,
                        editConfig: {
                            ariaLabel: 'csvField',
                            editIconAriaLabel: 'editable',
                            editingCell: (item, { currentValue, setValue }) => {
                                const value = currentValue ?? item.csvField;
                                return (
                                    <Select
                                        autoFocus={true}
                                        expandToViewport={true}
                                        selectedOption={
                                            csvFields
                                                ? csvFields.find(
                                                      (option) => option.value === value
                                                  ) ?? null
                                                : null
                                        }
                                        onChange={(event) => {
                                            const selectedValue = event.detail.selectedOption.value;
                                            setFieldsMapping((prevMappings) => {
                                                const newMappings = { ...prevMappings };
                                                newMappings[item.attribute] = selectedValue;
                                                return newMappings;
                                            });
                                            setValue(selectedValue ?? item.csvField);
                                        }}
                                        options={csvFields}
                                    />
                                );
                            }
                        },
                        cell: (item) => item.csvField
                    }
                ]}
                enableKeyboardNavigation
                items={fieldMatchItems}
            />
            {displayError && (
                <Alert statusIconAriaLabel='Error' header='Incomplete field mapping' type='error'>
                    {`Some CSV fields are not mapped to Part attributes. Please review the 'CSV Fields' column and ensure all fields are mapped.`}
                </Alert>
            )}
        </>
    );
}
