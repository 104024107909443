import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthTokens } from 'aws-amplify/auth';

export enum AuthStatus {
    configuring = 'configuring',
    authenticated = 'authenticated',
    unauthenticated = 'unauthenticated',
    invalid_profile = 'invalid_profile'
}

export type IdTokenType = AuthTokens['idToken'];

// Define a type for the slice state
export interface AuthState {
    authStatus: AuthStatus;
    idToken: IdTokenType;
}

// Define the initial state using that type
const initialState: AuthState = {
    authStatus: AuthStatus.configuring,
    idToken: undefined
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthStatus: (state, action: PayloadAction<AuthStatus>) => {
            state.authStatus = action.payload;
        },
        setIdToken: (state, action: PayloadAction<IdTokenType>) => {
            //@ts-ignore
            state.idToken = action.payload;
        }
    }
});

export const { setAuthStatus, setIdToken } = authSlice.actions;
export default authSlice.reducer;
