import React, { useState, useCallback } from 'react';
import { Modal, Box, SpaceBetween, Button, Alert } from '@amzn/awsui-components-react';

interface ConfirmationModalProps {
    title?: string;
    content?: React.ReactNode;
    confirmLabel?: string;
    cancelLabel?: string;
    onConfirm?: () => void;
}

export default function useConfirmationModal() {
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [modalProps, setModalProps] = useState<ConfirmationModalProps>({});

    const openModal = useCallback((props: ConfirmationModalProps) => {
        setModalProps(props);
        setModalVisible(true);
    }, []);

    const closeModal = useCallback(() => {
        setModalVisible(false);
    }, []);

    const ConfirmationModal = useCallback(
        () => (
            <Modal
                visible={modalVisible}
                header={modalProps.title || 'Leave page'}
                closeAriaLabel='Close modal'
                onDismiss={closeModal}
                footer={
                    <Box float='right'>
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Button variant='link' onClick={closeModal}>
                                {modalProps.cancelLabel || 'Cancel'}
                            </Button>
                            <Button
                                variant='primary'
                                onClick={() => {
                                    if (modalProps.onConfirm) {
                                        modalProps.onConfirm();
                                    }
                                    closeModal();
                                }}
                            >
                                {modalProps.confirmLabel || 'Leave'}
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                {modalProps.content || (
                    <Alert type='warning' statusIconAriaLabel='Warning'>
                        {`Are you sure that you want to leave the current page? The changes that you made won't be saved.`}
                    </Alert>
                )}
            </Modal>
        ),
        [modalVisible, modalProps, closeModal]
    );
    return { ConfirmationModal, openModal, closeModal };
}
