import React, { useState, useEffect } from 'react';
import {
    Input,
    SpaceBetween,
    Button,
    Header,
    Select,
    SelectProps,
    Container,
    Table,
    TableProps,
    Flashbar,
    FlashbarProps,
    ExpandableSection,
    FormField,
    Form,
    Box,
    Grid
} from '@amzn/awsui-components-react';
import CustomAppLayout from './common/CustomAppLayout';
import Breadcrumbs from './common/Breadcrumbs';
import './styles/partTable.scss';
import useAttributes, {
    NO_MAPPING_LABEL,
    MappingAttributesType,
    FormErrorsType,
    CreateAttributeFormType,
    DEFAULT_FORM_DATA
} from './hooks/useAttributes';
import useFlashMessage from './hooks/useFlashMessage';

const DEFAULT_PLM_ID = {
    label: 'Agile PLM',
    value: 'Agile PLM'
};

const DEFAULT_ERRORS: FormErrorsType = {
    attributeName: '',
    attributeType: ''
};

const ATTRIBUTE_CATEGORIES = [
    {
        label: 'Internal Attribute',
        value: 'internalAttribute'
    },
    {
        label: 'PLM Attribute',
        value: 'plmAttribute'
    },
    {
        label: 'Data Provider Attribute',
        value: 'dataProviderAttribute'
    }
];

export default function MappingPage() {
    const [selectedPlmId, setSelectedPlmId] = React.useState<SelectProps.Option>(DEFAULT_PLM_ID);
    const [flashMessages, setFlashMessages] = useState<FlashbarProps.MessageDefinition[]>([]);
    const [formData, setFormData] = useState<CreateAttributeFormType>(DEFAULT_FORM_DATA);
    const [formErrors, setFormErrors] = useState<FormErrorsType>(DEFAULT_ERRORS);
    console.log('flashMessages', flashMessages);

    const { addFlashMessage } = useFlashMessage({ setFlashMessages });

    const {
        isMappingsLoading,
        plmAttributesStatus,
        dataProviderAttributeStatus,
        attributeMappings,
        plmAttributesOptionsWithClear,
        dataProviderAttributesOptionsWithClear,
        handleTableInlineEditSubmit,
        handleFormSubmit,
        loadAttributes
    } = useAttributes({
        plmId: selectedPlmId.value ?? DEFAULT_PLM_ID.value,
        addFlashMessage,
        formErrors,
        setFormErrors,
        formData,
        setFormData
    });

    const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<MappingAttributesType>[] = [
        {
            id: 'internalAttribute',
            cell: (item) => item.internalAttribute,
            header: 'Internal Attribute',
            minWidth: '80px'
        },
        {
            id: 'plmAttribute',
            cell: (item) => item.plmAttribute || NO_MAPPING_LABEL,
            header: 'PLM Attribute',
            minWidth: '160px',
            editConfig: {
                ariaLabel: 'PLM Attribute',
                editIconAriaLabel: 'editable',
                editingCell: (item, { currentValue, setValue }) => {
                    const selectedOption =
                        plmAttributesOptionsWithClear.find(
                            (option) => option.value === (currentValue ?? item.plmAttribute)
                        ) || plmAttributesOptionsWithClear[0];
                    return (
                        <Select
                            selectedOption={selectedOption}
                            onChange={({ detail }) => {
                                setValue(detail.selectedOption.value ?? item.plmAttribute);
                            }}
                            options={plmAttributesOptionsWithClear}
                            filteringType='auto'
                            autoFocus={true}
                            expandToViewport={true}
                            ariaLabel='Select desired plm attribute'
                            statusType={plmAttributesStatus}
                        />
                    );
                }
            }
        },
        {
            id: 'dataProviderAttribute',
            cell: (item) => item.dataProviderAttribute || NO_MAPPING_LABEL,
            header: 'Data Provider Attribute',
            minWidth: '160px',
            editConfig: {
                ariaLabel: 'Data Provider Attribute',
                editIconAriaLabel: 'editable',
                editingCell: (item, { currentValue, setValue }) => {
                    const selectedOption =
                        dataProviderAttributesOptionsWithClear.find(
                            (option) =>
                                option.value === (currentValue ?? item.dataProviderAttribute)
                        ) || dataProviderAttributesOptionsWithClear[0];
                    return (
                        <Select
                            selectedOption={selectedOption}
                            onChange={({ detail }) => {
                                setValue(detail.selectedOption.value ?? item.dataProviderAttribute);
                            }}
                            options={dataProviderAttributesOptionsWithClear}
                            filteringType='auto'
                            autoFocus={true}
                            expandToViewport={true}
                            ariaLabel='Select desired data provider attribute'
                            statusType={dataProviderAttributeStatus}
                        />
                    );
                }
            }
        }
    ];

    const handleFormInputChange = (field: keyof typeof formData, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: value
        }));
    };

    useEffect(() => {
        loadAttributes(selectedPlmId.value || DEFAULT_PLM_ID.value);
    }, []);

    const selectedAttributeCategoryOption = ATTRIBUTE_CATEGORIES.filter(
        (item) => item.value === formData.attributeCategory
    );

    return (
        <CustomAppLayout
            notifications={<Flashbar items={flashMessages} stackItems />}
            breadcrumbs={
                <Breadcrumbs
                    items={[
                        { text: 'Home', href: '/parts' },
                        {
                            text: 'Mapping',
                            href: '/mapping'
                        }
                    ]}
                />
            }
            content={
                <Container
                    header={
                        <Header
                            variant='h1'
                            description='Select a PLM ID to manage attribute mappings'
                            actions={
                                <SpaceBetween direction='horizontal' size='xs'>
                                    <Select
                                        selectedOption={selectedPlmId}
                                        onChange={({ detail }) =>
                                            setSelectedPlmId(detail.selectedOption)
                                        }
                                        options={[
                                            { label: 'Agile PLM', value: 'Agile PLM' },
                                            { label: 'Teamcenter PLM', value: 'Teamcenter PLM' }
                                        ]}
                                        placeholder='Choose a PLM ID'
                                    />
                                </SpaceBetween>
                            }
                        >
                            Attribute Mapping
                        </Header>
                    }
                >
                    <SpaceBetween size='l'>
                        <ExpandableSection headerText='Create New Attribute' variant='container'>
                            <form onSubmit={handleFormSubmit}>
                                <Form
                                    actions={<Button variant='primary'>Create</Button>}
                                    errorIconAriaLabel='Error'
                                >
                                    <Grid
                                        gridDefinition={[
                                            { colspan: 4 },
                                            { colspan: 4 },
                                            { colspan: 4 }
                                        ]}
                                    >
                                        <FormField label='Attribute Category'>
                                            <Select
                                                selectedOption={selectedAttributeCategoryOption[0]}
                                                onChange={({ detail }) =>
                                                    handleFormInputChange(
                                                        'attributeCategory',
                                                        detail.selectedOption.value
                                                    )
                                                }
                                                options={ATTRIBUTE_CATEGORIES}
                                            />
                                        </FormField>
                                        <FormField
                                            label='Attribute Name'
                                            errorText={formErrors.attributeName}
                                        >
                                            <Input
                                                value={formData.attributeName}
                                                onChange={(e) =>
                                                    handleFormInputChange(
                                                        'attributeName',
                                                        e.detail.value
                                                    )
                                                }
                                            />
                                        </FormField>
                                        <FormField
                                            label='Attribute Type'
                                            errorText={formErrors.attributeType}
                                        >
                                            <Input
                                                value={formData.attributeType}
                                                onChange={(e) =>
                                                    handleFormInputChange(
                                                        'attributeType',
                                                        e.detail.value
                                                    )
                                                }
                                            />
                                        </FormField>
                                    </Grid>
                                </Form>
                            </form>
                        </ExpandableSection>
                        <Table
                            header={
                                <Header
                                    variant='h2'
                                    description='Mapping between internal attributes, PLM attributes, and data provider attributes'
                                >
                                    Attribute Mapping Table
                                </Header>
                            }
                            items={attributeMappings}
                            columnDefinitions={COLUMN_DEFINITIONS}
                            submitEdit={handleTableInlineEditSubmit}
                            variant='container'
                            loading={isMappingsLoading}
                            loadingText='Loading attributes'
                            empty={
                                <Box textAlign='center' color='inherit'>
                                    <b>No attributes</b>
                                    <Box padding={{ bottom: 's' }} variant='p' color='inherit'>
                                        No attributes to display
                                    </Box>
                                </Box>
                            }
                        />
                    </SpaceBetween>
                </Container>
            }
        />
    );
}
