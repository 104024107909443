import React, { useState, useEffect } from 'react';
import { getBomColumnDefinitions } from '../../config/details-config';
import {
    Header,
    SpaceBetween,
    Box,
    Multiselect,
    Link,
    Table,
    Alert,
    SelectProps
} from '@amzn/awsui-components-react';
import { getHeaderCounterText, getData } from '../../utils/commons';
import { CustomBillOfMaterial } from '../types';

interface BillOfMaterialsTableProps {
    ids: string[];
}

export default function BillOfMaterialsTable({ ids }: BillOfMaterialsTableProps) {
    const [expandedItems, setExpandedItems] = useState<CustomBillOfMaterial[]>([]);
    const [expandedNoCompItems, setExpandedNoCompItems] = useState<CustomBillOfMaterial[]>([]);
    const [billofmaterials, setBillofmaterials] = useState<CustomBillOfMaterial[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedItems, setSelectedItems] = useState<CustomBillOfMaterial[]>([]);
    const [selectedSpecifications, setSelectedSpecifications] = useState<SelectProps.Options>([]);
    const isAccessAble: boolean = true;
    // fetch billofmaterials after render of the component
    useEffect(() => {
        getData<CustomBillOfMaterial>('billofmaterials', (billofmaterials) => {
            addParentPath(billofmaterials, {
                name: ids[ids.length - 1],
                parentPath: ids.slice(0, ids.length - 1).join('/')
            });
            setBillofmaterials(billofmaterials);
            setLoading(false);
        });
    }, []);
    const addParentPath = (
        billofmaterials: CustomBillOfMaterial[],
        parent: { name: string; parentPath: string }
    ) => {
        for (const material of billofmaterials) {
            material.parentPath = `${parent.parentPath || ''}/${parent.name || ''}`;
            if (material.children) {
                addParentPath(material.children, material);
            }
        }
    };

    const BOM_COLUMN_DEFINITIONS = getBomColumnDefinitions();
    const AlertMessage = () => {
        return (
            <Alert statusIconAriaLabel='Error' type='error' header='Access denied'>
                {`You don't have permission to access this bill of material.`}
            </Alert>
        );
    };

    return (
        <SpaceBetween size='l'>
            {isAccessAble ? (
                <Table
                    enableKeyboardNavigation={true}
                    loading={loading}
                    loadingText='Loading billofmaterials'
                    columnDefinitions={BOM_COLUMN_DEFINITIONS}
                    items={billofmaterials.slice(0, 5)}
                    selectionType='multi'
                    selectedItems={selectedItems}
                    onSelectionChange={(event) => setSelectedItems(event.detail.selectedItems)}
                    expandableRows={{
                        getItemChildren: (item) => item.children ?? [],
                        isItemExpandable: (item) => Boolean(item.children),
                        expandedItems: expandedItems,
                        onExpandableItemToggle: ({ detail }) => {
                            setExpandedItems((prev) => {
                                let newItems = [...prev];
                                if (detail.expanded) {
                                    newItems.push(detail.item);
                                } else {
                                    newItems = newItems.filter(
                                        (item) => item.name !== detail.item.name
                                    );
                                }
                                return newItems;
                            });
                        }
                    }}
                    trackBy='name'
                    header={
                        <Header
                            counter={
                                loading ? '' : getHeaderCounterText(billofmaterials, selectedItems)
                            }
                            actions={
                                <SpaceBetween direction='horizontal' size='xl'>
                                    <Multiselect
                                        selectedOptions={selectedSpecifications}
                                        onChange={({ detail }) => {
                                            setSelectedSpecifications(detail.selectedOptions);
                                        }}
                                        options={[
                                            {
                                                label: 'Specification 1',
                                                value: '1'
                                            },
                                            {
                                                label: 'Specification 2',
                                                value: '2'
                                            },
                                            {
                                                label: 'Specification 3',
                                                value: '3'
                                            },
                                            {
                                                label: 'Specification 4',
                                                value: '4'
                                            },
                                            { label: 'Specification 5', value: '5' }
                                        ]}
                                        placeholder='Choose Specifications'
                                    />
                                </SpaceBetween>
                            }
                        >
                            BOM
                        </Header>
                    }
                    footer={
                        <Box textAlign='center'>
                            <Link>View all BOM</Link>
                        </Box>
                    }
                />
            ) : (
                <AlertMessage />
            )}

            {selectedSpecifications.length > 0 && (
                <Table
                    enableKeyboardNavigation={true}
                    loading={loading}
                    loadingText='Loading billofmaterials'
                    columnDefinitions={BOM_COLUMN_DEFINITIONS}
                    items={billofmaterials
                        .filter((part) => part.complianceStatus == 'Non-Compliant')
                        .map((part) => ({
                            ...part,
                            children: part.children?.filter(
                                (childPart) => childPart.complianceStatus == 'Non-Compliant'
                            )
                        }))}
                    expandableRows={{
                        getItemChildren: (item) => item.children ?? [],
                        isItemExpandable: (item) => Boolean(item.children),
                        expandedItems: expandedNoCompItems,
                        onExpandableItemToggle: ({ detail }) => {
                            setExpandedNoCompItems((prev) => {
                                let newItems = [...prev];
                                if (detail.expanded) {
                                    newItems.push(detail.item);
                                } else {
                                    newItems = newItems.filter(
                                        (item) => item.name !== detail.item.name
                                    );
                                }
                                return newItems;
                            });
                        }
                    }}
                    trackBy='name'
                    header={
                        <Header
                            counter={
                                loading ? '' : getHeaderCounterText(billofmaterials, selectedItems)
                            }
                        >
                            BOM - Not Compliant
                        </Header>
                    }
                />
            )}
        </SpaceBetween>
    );
}
