import React from 'react';
import { Flashbar, FlashbarProps } from '@amzn/awsui-components-react';
import CustomAppLayout from '../common/CustomAppLayout';

export default function SignInLoadingPage() {
    const loadingAuthMessage: Array<FlashbarProps.MessageDefinition> = [
        {
            header: 'Signing in using Midway',
            loading: true,
            content: 'Please wait while we check for your credentials.'
        }
    ];
    return (
        <CustomAppLayout
            notifications={<Flashbar items={loadingAuthMessage} stackItems />}
            navigationHide
            signoutHide
        />
    );
}
