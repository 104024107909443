import { FlashbarProps } from '@amzn/awsui-components-react';
export enum StatusIndicatorType {
    ERROR = 'error',
    WARNING = 'warning',
    SUCCESS = 'success',
    INFO = 'info',
    STOPPED = 'stopped',
    PENDING = 'pending',
    IN_PROGRESS = 'in-progress',
    LOADING = 'loading'
}

export interface FieldMatchTableType {
    attribute: string;
    csvField: string | undefined;
}

export interface FieldsMappingType {
    manufacturer: string;
    manufacturerPartNumber: string;
}

export const DEFAULT_FIELDS_MAPPING = {
    manufacturer: 'None',
    manufacturerPartNumber: 'None'
};

export interface CSVField {
    label: string;
    value: string;
    tags: string[];
}

export interface ParsedData {
    [key: string]: string;
}

export enum SubstanceState {
    MISSING = 'MISSING',
    SUCCESS = 'SUCCESS'
}

export enum SubstanceAgileEditOption {
    NEW = 'New',
    ALIAS = 'Alias'
}

export interface SubstanceAgileEditInputType {
    inputValue: string;
    controlId: SubstanceAgileEditOption;
}

export interface MissingSubstance {
    id: string;
    name: string;
    agileName: string;
    casNumber: string;
    agileFmd: string;
    status: SubstanceState;
}

export interface Specification {
    id: number;
    name: string;
    lifeCyclePhase: string;
    calculatedCompliance: CalculatedCompliance;
    declaredCompliance: string;
    exemptions: string;
    exemptionExpiration: string;
    notes: string;
    declaredComplianceStatus: DeclaredComplianceStatus;
}

export enum CalculatedCompliance {
    COMPLIANT = 'Compliant',
    MISSING_INFO = 'Missing Info'
}

export enum DeclaredComplianceStatus {
    APPROVED = 'Approved',
    PENDING = 'Pending',
    REJECTED = 'Rejected',
    MISSING = 'Missing'
}

export interface BillOfMaterial {
    name: string;
    description: string;
    itemRev: string;
    quantity: number;
    complianceStatus: ComplianceStatus;
    children?: CustomBillOfMaterial[];
}

export interface CustomBillOfMaterial extends BillOfMaterial {
    parentPath: string;
}

export enum ComplianceStatus {
    COMPLIANT = 'Compliant',
    NON_COMPLIANT = 'Non-Compliant',
    UNKNOWN = 'Unknown'
}

export interface Audit {
    id: string;
    user: string;
    operation: string;
    time: string;
}

export interface Attachment {
    fileName: string;
    fileLink: string;
    fileDescription: string;
    fileType: string;
    fileSize: number;
    uploadTime: string;
    uploadUser: string;
}

export enum DisclosedStatus {
    PARTIALLY_DISCLOSED = 'Partially Disclosed',
    FULLY_DISCLOSED = 'Fully Disclosed'
}
